<template>
  <tr :id="rowId" v-if="!!winch">
    <td>{{ winch.city }}</td>
    <td>
      <span class="link">{{ winch.price }}</span
      >&nbsp;دينار
    </td>
    <td>
      <modal-btn btn-class="btn-sm btn-primary" :target="`#${editId}`">
        <i class="fas fa-pen"></i>
      </modal-btn>
      <modal-btn btn-class="btn-sm btn-soft-danger" :target="`#${deleteId}`">
        <i class="fas fa-trash-alt"></i>
      </modal-btn>
    </td>
    <teleport to="#app">
      <confirm-model
        :target-id="editId"
        title="تعديل تسعيرة ونش"
        confirm-text="تعديل"
        @confirmed="clickById(`edit-winch-submit${winch.id}`)"
      >
        <form @submit.prevent="update">
          <input type="hidden" name="id" :value="winch.id" />
          <input type="hidden" name="_method" value="PUT" />
          <div class="row">
            <div class="col-sm-3">المحافظة</div>
            <div class="col-sm-9">
              <bootstrap-select
                title="المحافظة"
                :id="`city_id${winch.id}`"
                name="city_id"
                :options="$store.getters.cities"
                :selected="winch.city_id"
              ></bootstrap-select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">سعر المزاد</div>
            <div class="col-sm-9">
              <form-input
                :id="`price${winch.id}`"
                name="price"
                placeholder="سعر المزاد"
                type="number"
                :value="winch.price"
              ></form-input>
            </div>
          </div>
          <button
            hidden
            type="submit"
            :id="`edit-winch-submit${winch.id}`"
          ></button>
        </form>
      </confirm-model>
      <items-destroyer
        title="حذف تسعيرة ونش"
        :target-id="deleteId"
        :url="`${defaultUrl}/${winch.id}`"
        :row-id="`winch${winch.id}`"
        @deleted="deleted"
      >
        <span>هل أنت من حذف تسعيرة المنطقة</span>&nbsp;<span class="link">{{
          winch.city
        }}</span
        >&nbsp;؟
      </items-destroyer>
    </teleport>
  </tr>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import BootstrapSelect from "@/components/Forms/BootstrapSelect";
import FormInput from "@/components/Forms/FormInput";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";
import Storage from "@/common/Storage";

export default {
  components: {
    ItemsDestroyer,
    FormInput,
    BootstrapSelect,
    ConfirmModel,
    ModalBtn,
  },
  props: { winch: { required: true } },
  inject: ["defaultUrl"],
  emits: ["deleted", "update"],
  computed: {
    deleteId() {
      if (!this.winch) return "";
      return `delete-winch${this.winch.id}`;
    },
    editId() {
      if (!this.winch) return "";
      return `edit-winch${this.winch.id}`;
    },
    rowId() {
      if (!this.winch) return "";
      return `winch${this.winch.id}`;
    },
  },
  methods: {
    clearStorage() {
      Storage.removeStartWith("invoice-auction");
    },
    update(e) {
      this.clearStorage();
      return this.$emit("update", e);
    },
    deleted(id) {
      this.clearStorage();
      return this.$emit("deleted", id);
    },
  },
};
</script>
