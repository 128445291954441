<template>
  <div>
    <div class="w-100 text-right">
      <modal-btn btn-class="btn-sm btn-soft-danger" target="#add-winch">
        إضافة&nbsp;<i class="fa fa-plus"></i>
      </modal-btn>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered" :class="{shadow: !emptyList}">
        <tr class="bg-bb">
          <th>المحافظة</th>
          <th>التكلفة</th>
          <th>تعديل</th>
        </tr>
        <tr v-if="emptyList">
          <th colspan="3">لا يوجد</th>
        </tr>
        <winch-item v-for="winch in list" :winch="winch" :key="winch.id" @update="update" @deleted="deleted"></winch-item>
      </table>
    </div>
    <ThePaginator
      :total="total"
      :url="defaultUrl"
      :last-page="lastPage"
      :links="links"
      @link-changed="setLink"
    />
    <teleport to="#app">
      <confirm-model
        target-id="add-winch"
        title="إضافة تسعيرة ونش"
        confirm-text="إضافة"
        @confirmed="clickById('add-winch-submit')">
        <form @submit.prevent="store">
          <div class="row">
            <div class="col-sm-3">المحافظة</div>
            <div class="col-sm-9">
              <bootstrap-select title="المحافظة" id="city_id" :options="$store.getters.cities"></bootstrap-select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">السعر</div>
            <div class="col-sm-9">
              <form-input id="price" placeholder="السعر" type="number"></form-input>
            </div>
          </div>
          <button hidden type="submit" id="add-winch-submit"></button>
        </form>
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn";
import ThePaginator from "@/components/UI/ThePaginator";
import ConfirmModel from "@/components/UI/ConfirmModel";
import BootstrapSelect from "@/components/Forms/BootstrapSelect";
import FormInput from "@/components/Forms/FormInput";
import pagination from "@/mixins/pagination";
import WinchItem from "@/components/Pages/Companies/WinchItem";
import Storage from "@/common/Storage";

export default {
  components: {WinchItem, FormInput, BootstrapSelect, ConfirmModel, ThePaginator, ModalBtn},
  mixins: [pagination],
  inject: ['formData', 'company'],
  provide() {
    return {
      defaultUrl: this.defaultUrl
    };
  },
  computed: {
    defaultUrl() {
      return '/winches';
    },
    storageName() {
      return `winches-${this.company}`;
    }
  },
  methods: {
    clearStorage() {
      Storage.removeStartWith('invoice-auction');
    },
    async setWinches() {
      this.storage = true;
      await this.$store.dispatch('setCitiesAction');
      await this.setList();
    },
    setResponseData(response, modal) {
      if (!response)
        return false;
      this.clearStorage();
      this.setPagination(response.data);
      this.hideModal(modal)
      http.popupMessage('success', messages.saved);
    },
    async store(e) {
      if (!this.company)
        return this.companyNotSpecified();
      const formData = new FormData(e.target);
      formData.set('company', this.company);
      const response = await http.send('winches', formData);
      if (http.responseAccepted(response))
        this.setResponseData(response, '#add-winch');
    },
    async update(e) {
      if (!this.company)
        return this.companyNotSpecified();
      const formData = new FormData(e.target);
      const id = formData.get('id');
      formData.set('company', this.company);
      http.inputsId = id;
      const response = await http.send(`winches/${id}`, formData);
      if (http.responseAccepted(response))
        this.setResponseData(response, `#edit-winch${id}`);
      http.inputsId = '';
    },
    companyNotSpecified() {
      return http.popupMessage('error', 'لم يتم تحديد الشركة');
    }
  },
  async created() {
    await this.setWinches();
  }
}
</script>